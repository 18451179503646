export default class Live {
  id = null
  avatar = null
  username = null
  name = null
  price = null
  type_stream = null
  access = null
  stream_id = null

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (!Object.hasOwnProperty.call(this, key)) continue
      if (key === 'username' && data[key]) this[key] = data[key].toLowerCase()
      else this[key] = data[key]
    }
  }

  get avatarUrl() {
    return process.env.VUE_APP_STORAGE_URL + this.avatar
  }
}